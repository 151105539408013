import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

@NgModule({
    imports: [
        RouterModule.forRoot([
            { path: '', pathMatch: 'full', redirectTo: 'view' },
            { path: 'activation', loadChildren: () => import('./public-area/activation/activation-routes').then(m => m.ACTIVATION_ROUTES) },
            { path: 'activation-with-google', loadChildren: () => import('./public-area/activation-with-google/activation-with-google-routes').then(m => m.ACTIVATION_WITH_GOOGLE_ROUTES) },
            { path: 'login', loadChildren: () => import('./public-area/login/login-routes').then(m => m.LOGIN_ROUTES) },
            { path: 'forgot-password', loadChildren: () => import('./public-area/forgot-password/forgot-password-routes').then(m => m.FORGOT_PASSWORD_ROUTES) },
            { path: 'password-reset', loadChildren: () => import('./public-area/password-reset/password-reset-routes').then(m => m.PASSWORD_RESET_ROUTES) },
            { path: 'view', loadChildren: () => import('./private-area/private-area-routes').then(m => m.PRIVATE_AREA_ROUTES) }
        ], { canceledNavigationResolution: 'computed' }) // needed to compute correctly history.back
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }